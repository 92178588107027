@import "../variables.scss";

.footer {
  color: $color-studio-breadcrumb;
  font-size: 14px;
  font-weight: 500;

  width: 100%;
  padding: 20px 0px;
}

.footerLinks {
  color: $color-studio-breadcrumb;
}

.footerLinks a:visited {
  color: $color-studio-breadcrumb;
}
