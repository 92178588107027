.heroContainer {
  width: 100%;
  height: 800px;
  background-image: url("../images/gateway/headerbg.jpg");
  background-size: cover;
}

.heroBackground {
  width: 100%;
  height: 100%;
  background-image: url("../images/gateway/stars.png");
  background-size: 1024px;
}

.heroImage {
  width: 100%;
  height: 740px;
  background-image: url("../images/gateway/gwrocks.png");
  background-position: calc(50% - 500px) top;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
}

.container {
  width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 1440px) {
  .container {
    width: 100%;
    min-width: 480px;
    padding: 0 20px;
  }

  .heroImage {
    background-position: calc(50% - 250px) top;
  }
}

.headerContainer {
  background-color: #000000;
}

.heroTextBox {
  margin-left: calc(45% + 150px);
  margin-top: 120px;

  color: #fff;

  h1 {
    font-size: 48px;
  }
}

.pageContainer {
  background-color: #000;
}
