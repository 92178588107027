$color-background: #1b212b;

$color-marketing-body: #fff;

$color-accent-light: #9eb6fe;
$color-accent-dark: #3b65e1;

$color-studio-body: #eff5ff;
$color-studio-body-muted: #9ba6b9;
$color-studio-body-mutedDarker: #8a94a5;
$color-studio-link: #a1c7ff;
$color-studio-breadcrumb: #b5bcd8;

/*
    The real weights of these are +100
    Font rendering is awful.
*/
$weight-regular: 300;
$weight-medium: 400;
$weight-semibold: 400;
$weight-bold: 600;
$weight-extrabold: 700;
