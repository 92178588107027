.container {
  width: 1300px;
  margin: 0 auto;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-flow: column nowrap;
}

.containerFull {
  flex: 1 0 auto;
}

@media screen and (max-width: 1300px) {
  .container {
    width: 100%;
    padding: 0 0px;
    min-width: 1300px;
  }
}

.marketingContainer {
  width: 1140px;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 1200px) {
  .marketingContainer {
    width: 100%;
    min-width: 480px;
    padding: 0 20px;
  }
}
