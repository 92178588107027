@import "./variables.scss";
@import "./contextmenu.scss";

html,
body {
  height: 100%;
  min-height: 100%;
  margin: 0;
}

body {
  font-family: system-ui, sans-serif;
  font-variant-numeric: lining-nums;
  background: $color-background;
}

a {
  text-decoration: none;
}

hr {
  border: 1px solid rgba(121, 142, 204, 0.13);
  margin-top: 20px;
  margin-bottom: 20px;
}

.root {
  height: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  flex-flow: column nowrap;
  justify-content: flex-start;
}

.container-fluid {
  padding: 0px;
}

/* total width */
::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  cursor: pointer;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

dl.dldata dd {
  display: inline;
  margin: 0;
}

dl.dldata dd:after {
  display: block;
  content: "";
}

dl.dldata dt {
  display: block;
  font-size: 14px;
  min-width: 100px;
}

.monaco-editor .codelens-decoration > span {
  vertical-align: middle !important;
  font-weight: 500;
}
