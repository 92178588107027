@import "../variables.scss";

.styleTitle {
  font-weight: $weight-bold;
  font-size: 48px;
  line-height: 56px;
}

.styleTitle2 {
  font-weight: $weight-semibold;
  font-size: 24px;
  line-height: 32px;
}

.styleMarketingBody {
  font-weight: $weight-medium;
  font-size: 18px;
  line-height: 20px;
}

.styleSmall {
  font-weight: $weight-semibold;
  font-size: 16px;
  line-height: 18px;
}

.styleSubtitle {
  font-weight: $weight-semibold;
  font-size: 20px;
  line-height: 21px;
}

.styleBody {
  font-weight: $weight-semibold;
  font-size: 16px;
  line-height: 19px;
}

.colorMarketingBody {
  color: $color-marketing-body;
}

.colorStudioBody {
  color: $color-studio-body;
}

.colorLink {
  color: $color-studio-link;
}

.colorLink:hover {
  cursor: pointer;
  color: lighten($color-studio-link, 10%);
}

.colorMuted {
  color: $color-studio-body-muted;
}

.colorMutedMore {
  color: $color-studio-body-mutedDarker;
}

.block {
  display: block;
}
