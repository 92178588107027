.studioContainer {
  display: flex;
  flex: 1;
}

.loadingContainer {
  margin: 0 0;
  width: 100%;
  height: 100%;
}

.loadingMenuBar {
  background-color: #3C3C3C;
  height: 30px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.loadingSidebar {
  background-color: #333333;
  height: 100%;
  width: 248px;
  position: absolute;
  top: 0;
  left: 0;
}

.loadingContent {
  background-color: rgb(30, 30, 30);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}