@import "../variables.scss";

.headerContainer {
  width: 100%;
  height: 720px;
  background-image: url("../images/hero-bg.png");
  background-size: 100% 780px;
  background-position: center;
  position: absolute;
  z-index: -100;
}

@media screen and (min-width: 1920px) {
  .headerContainer {
    background-size: 100% 780px;
  }
}

.bigFeature {
  text-align: center;
  width: 300px;
  margin: 0 auto;
  color: $color-marketing-body;
}

.bigFeature h1 {
  font-weight: $weight-semibold;
  font-size: 28px;
  line-height: 28px;
  margin: 0px;
  margin-bottom: 8px;
}

.bigFeature p {
  font-size: 18px;
  line-height: 20px;
  font-weight: $weight-semibold;
  margin: 0px;
}

.bigFeature p strong {
  display: block;
  font-weight: $weight-bold;
  margin-bottom: 20px;
}

.ctaButton {
  font-size: 22px;
  padding: 10px 20px;
}
