@import "../variables.scss";

.button {
  padding: 0px 20px;
  border: none;
  color: rgba($color-marketing-body, 0.85);
  border-radius: 6px;

  font-weight: $weight-semibold;
  font-size: 18px;
  line-height: 40px;

  box-sizing: border-box;
  display: inline-block;
  transition: background-color 125ms ease-in-out;
  background-color: rgba(white, 0);
  user-select: none;
  cursor: pointer;
}

.button:disabled {
  cursor: not-allowed;
}

.outline {
  box-shadow: inset 0px 0px 0px 2px $color-marketing-body;
}

.outline:hover:not([disabled]) {
  background-color: rgba($color-marketing-body, 0.15);
  color: $color-marketing-body;
}
