@import "../variables.scss";

.navbarItem {
  display: inline-block;

  color: rgba($color-marketing-body, 0.85);
  transition: color 125ms ease-in-out;

  font-size: 18px;
  font-weight: $weight-semibold;
  line-height: 40px;

}

.navbarItem:hover {
  color: $color-marketing-body;
  cursor: pointer;
}

.navbarItem .active {
  color: $color-marketing-body;
}