@import "../variables.scss";

.container {
  padding-top: 66px;
  margin-bottom: auto;
  overflow: auto;
}
.logo {
  height: 74px;
  width: 230px;
  background-image: url('../images/pylon-logo.svg');
}